@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap');

body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h4,
h5,
h6,
p {
  color: #172121;
}

p {
  font-weight: 300;
}

a {
  text-decoration: underline;
}

.error {
  color: red !important
}

.leaflet,
.leaflet-container {
  height: 100%;
  width: 100%;
  z-index: 0 !important;
}

.leaflet-bar a {
  color: #8ABC94;
}

.leaflet-top .leaflet-control {
  margin-top: 90px !important;
}

.leaflet-right .leaflet-control {
  margin-right: 15px !important;
}

.leaflet-popup {
  font-family: "Comfortaa";
  font-size: large;
  color:#8ABC94 !important;
  font-weight: bold;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color:#8ABC94 !important;
}

.green-light-hr {
  width: 100%;
  border: 1px solid #8ABC94;
  margin: 15px 0;
}
